import Vue from 'vue';
import Vuex from 'vuex';
import { v4 } from 'uuid';

Vue.use(Vuex);

const getLocal = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return null;
  }
};

const coordinates = getLocal('coordinates') || [];
const history = getLocal('history') || [];

export default new Vuex.Store({
  state: {
    coordinateId: '',
    coordinates,
    history,
    highlightPoint: null,
  },
  mutations: {
    setCoordinates(state, payload) {
      state.coordinates = payload;
      localStorage.setItem('coordinates', JSON.stringify(state.coordinates));
    },
    addCoordinate(state, payload) {
      state.coordinates.push(payload);
      localStorage.setItem('coordinates', JSON.stringify(state.coordinates));
    },
    removeCoordinate(state, index) {
      state.coordinates.splice(index, 1);
      localStorage.setItem('coordinates', JSON.stringify(state.coordinates));
    },
    updateCoordinate(state, { index, value }) {
      state.coordinates[index] = value;
      localStorage.setItem('coordinates', JSON.stringify(state.coordinates));
    },
    setHistory(state, payload) {
      state.history = payload;
      localStorage.setItem('history', JSON.stringify(state.history));
    },
    addHistory(state) {
      state.history.push({ coordinates: state.coordinates || [], createdAt: new Date(), id: v4() });
      localStorage.setItem('history', JSON.stringify(state.history));
    },
    udpateHistory(state, data) {
      const { id } = data;
      const index = state.history.findIndex((item) => item.id === id);
      state.history[index] = data;
      localStorage.setItem('history', JSON.stringify(state.history));
    },
    setHighlightPoint(state, payload) {
      state.highlightPoint = payload;
    },
    loadCoordinates() {},
  },
  actions: {
    resetCoordinates(context) {
      context.commit('addHistory');
      context.commit('setCoordinates', []);
    },
  },
  modules: {},
});
